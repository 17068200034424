<template>
  <div class="py-7 px-7">
    <v-card flat style="background: #f7f7f7">
      <v-card-title class="px-0">
        <v-tabs
          color="#2C1963"
          class="elevation-5"
          style="border-radius: 10px"
          v-model="selected"
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab
            v-for="(item, i) in tabs"
            :key="i"
            class="text-capitalize mx-5"
          >
            {{ item.title }}
          </v-tab>
        </v-tabs>
      </v-card-title>
      <div class="text-center" v-if="loading" style="min-height: 500px">
        <v-progress-circular
          :size="100"
          :width="10"
          style="
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 200px;
          "
          color="#7253CF"
          indeterminate
          ><v-overlay></v-overlay
        ></v-progress-circular>
      </div>
      <div v-else>
        <v-card-text v-if="selectedKey === 'campaign'">
          <v-row justify="start">
            <v-col cols="3" v-for="(item, i) in campaignAssets" :key="i">
              <div
                class="gallery-image-container pa-2"
                v-if="item.type === 'image'"
              >
                <img
                  class="gallery-image"
                  @click="preview(item)"
                  :src="`${item.file_url}`"
                  alt=""
                />
              </div>
              <div v-else class="gallery-image-container pa-2">
                <video
                  class="gallery-video"
                  @click="preview(item)"
                  :src="`${item.file_url}`"
                ></video>
                <img
                  src="@/assets/play.png"
                  class="playButton"
                  width="40"
                  alt=""
                />
              </div>
              <v-menu offset-y left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    v-bind="attrs"
                    v-on="on"
                    style="position: relative; bottom: 98%; float: right"
                    v-if="item.assests_type === 'student'"
                  >
                    <v-icon size="25" color="#6B6B6B"
                      >mdi-dots-horizontal</v-icon
                    >
                  </v-btn>
                </template>
                <v-list class="menu-list moreOptionsStyle py-0">
                  <v-list-item-group class="py-0">
                    <v-list-item style="min-height: 40px">
                      <v-icon left size="18">mdi-pencil</v-icon>
                      <v-list-item-title
                        style="font-size: 14px"
                        @click="addToCampaignGallery(item, false)"
                      >
                        Remove From Campaign Gallery
                      </v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else>
          <v-row justify="start">
            <v-col
              cols="3"
              class="py-6"
              v-for="(item, i) in studentAssets"
              :key="i"
            >
              <!--<div class="px-5">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      class="elevation-2"
                      v-on="on"
                      v-if="!item.is_approved"
                      color="#2c1963"
                      @click="addToCampaignGallery(item)"
                      >mdi-image-plus</v-icon
                    >
                  </template>
                  <span>Add to Campaign Gallery</span>
                </v-tooltip>
              </div>-->
              <div
                class="gallery-image-container pa-2"
                v-if="item.type === 'image'"
              >
                <img
                  class="gallery-image"
                  @click="preview(item)"
                  :src="`${item.file_url}`"
                  alt=""
                />
              </div>
              <div v-else class="gallery-image-container pa-2">
                <video
                  class="gallery-video"
                  @click="preview(item)"
                  :src="`${item.file_url}`"
                ></video>
                <img
                  src="@/assets/play.png"
                  class="playButton"
                  width="40"
                  alt=""
                  @click="preview(item)"
                />
              </div>
              <v-menu offset-y left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    v-bind="attrs"
                    v-on="on"
                    style="position: relative; bottom: 98%; float: right"
                  >
                    <v-icon size="25" color="#6B6B6B"
                      >mdi-dots-horizontal</v-icon
                    >
                  </v-btn>
                </template>
                <v-list class="menu-list moreOptionsStyle py-0">
                  <v-list-item-group class="py-0">
                    <v-list-item style="min-height: 40px">
                      <v-icon left size="18">mdi-image-plus</v-icon>
                      <v-list-item-title
                        style="font-size: 14px"
                        @click="addToCampaignGallery(item, true)"
                      >
                        Add to Campaign Gallery
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item style="min-height: 40px">
                      <v-icon left size="18">mdi-delete</v-icon>
                      <v-list-item-title
                        style="font-size: 14px"
                        @click="deleteAsset(item)"
                      >
                        Delete From Gallery
                      </v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
    </v-card>
    <full-screen-image-viewer
      :imageViewer="imageViewer"
      v-if="imageViewer.flag"
    ></full-screen-image-viewer>
  </div>
</template>
<script>
import {
  GET_REPOSITORY_ASSETS,
  PATCH_API_ADDING_STUDENT_ASSET_TO_CAMPAIGN,
  API_STUDENT_STORE_ASSETS,
} from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "GalleryManagement",
  components: {
    FullScreenImageViewer: () =>
      import("@/components/VideoPlayer/FullScreenImageViewer"),
  },
  data() {
    return {
      selected: 0,
      tabs: [
        { key: "campaign", title: "Campaign Gallery" },
        { key: "student", title: "Student Gallery" },
      ],
      campaignAssets: [],
      studentAssets: [],
      loading: true,
      imageViewer: {
        src: "",
        flag: false,
        type: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      selectedCampaign: "campaign/getSelectedCampaign",
    }),
    selectedKey() {
      return this.tabs[this.selected].key;
    },
  },
  watch: {
    selected() {
      this.getGalleryAssets();
    },
  },
  methods: {
    ...mapActions({
      showToast: "snackBar/showToast",
    }),
    getGalleryAssets() {
      this.loading = true;
      const successHandler = (res) => {
        this.campaignAssets = res.data.campaign_assests_list.concat(
          res.data.approved_student_assests_list,
          res.data.teacher_assests_list
        );
        this.studentAssets = res.data.student_assests_list;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["campaign_id"] = this.selectedCampaign.campaignData.id;
      Axios.request_GET(
        GET_REPOSITORY_ASSETS,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    addToCampaignGallery(item, status) {
      console.log(item);
      const successHandler = (res) => {
        console.log(res);
        item.is_approved = status;
        this.getGalleryAssets();
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      let formData = {};
      formData["id"] = item.id;
      formData["campaign"] = this.selectedCampaign.campaignData.id;
      formData["is_approved"] = status;
      formData["type"] = item.type;
      Axios.request_PATCH(
        PATCH_API_ADDING_STUDENT_ASSET_TO_CAMPAIGN,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    preview(item) {
      console.log(item);
      this.imageViewer.src = item.file_url;
      this.imageViewer.type = item.type;
      this.imageViewer.flag = true;
    },
    deleteAsset(item) {
      // console.log(item);
      const successHandler = (res) => {
        console.log(res);
        this.showToast({
          message: "Deleted",
          color: "s",
        });
      };
      const failureHandler = (res) => {
        console.log(res);
        this.showToast({
          message: res,
          color: "e",
        });
      };
      const finallyHandler = () => {
        this.getGalleryAssets();
      };
      let formData = {};
      formData["assests_id"] = item.id;
      Axios.request_DELETE(
        API_STUDENT_STORE_ASSETS,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
  },
  mounted() {
    setTimeout(() => {
      this.getGalleryAssets();
    }, 800);
  },
  created() {
    this.$root.$refs.galleryManagement = this;
  },
};
</script>
<style scoped>
.gallery-image-container {
  width: 100%;
  border: 0.5px solid #2c1963;
  box-shadow: 1px 3px 7px;
  border-radius: 10px;
  height: 100%;
  vertical-align: middle;
  display: inline-block;
}
.gallery-image {
  width: 100%;
  border-radius: 10px;
  vertical-align: middle;
  margin: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.gallery-video {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  vertical-align: middle;
  cursor: pointer;
}
.playButton {
  position: relative;
  bottom: 58%;
  left: 45%;
  cursor: pointer;
}
.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
}
span.v-chip {
  border-radius: 4px;
  margin-right: 12px;
}
</style>
